export default [
  {
    index: 0,
    name: "jack",
    sticker: "jack",
    button: "noise",
    scale: {
      desktop: 2.2,
      mobile: 2,
      button: 0.8,
    },
    anchor: "center",
    background: {
      desktop: "jack",
    },
    innerBackground: {
      desktop: null,
      mobile: null,
    },
    overlayOpacity: 0,
    cells: [["bubbles"]],

    animationStyle: "grow-d",
    soundtrack: "Spiral 05 Stereo",

    sfx: ["C1", "C2", "C3", "C4", "C5", "C6"],
    volume: 0.15,
  },

  {
    index: 1,
    name: "selfie",
    sticker: "selfie",
    button: "ok-3d",
    scale: {
      desktop: 1.9,
      mobile: 1.5,
      button: 1,
    },
    anchor: "bottom",
    background: {},
    innerBackground: {
      desktop: null,
      mobile: null,
    },
    overlayOpacity: 0,
    cells: [
      ["smiley-clap"],
      ["smiley-heart"],
      ["smiley-vomit"],
      ["smiley-pray"],
      ["smiley-thumbs-up"],
      ["smiley-brain-boom"],
    ],
    animationStyle: "grow-c",
    soundtrack: "Gold Head Option 01",
    sfx: ["B1", "B2", "B3", "B4", "B5", "B6"],
    volume: 0.15,
  },

  {
    index: 2,
    name: "juniper",
    sticker: "juniper",
    button: "space",
    scale: {
      desktop: 0.8,
      mobile: 0.8,
      button: 1,
    },
    anchor: "center",
    background: {
      desktop: "juniper",
      mobile: "juniper",
    },
    innerBackground: {
      desktop: null,
      mobile: null,
    },
    overlayOpacity: 0,

    cells: [
      ["pink-letter-i"],
      ["pink-letter-n"],
      ["pink-letter-l"],
      ["pink-letter-o"],
      ["pink-letter-v"],
      ["pink-letter-e"],
    ],
    animationStyle: "grow-c",
    soundtrack: "Gold Head Option 01",
    sfx: ["D1", "D2", "D3", "D4", "D5", "D6"],
    volume: 0.15,
  },
  {
    index: 3,
    name: "disco",
    sticker: "disco",
    button: "eclipse",
    scale: {
      desktop: 0.85,
      mobile: 0.73,
      button: 1,
    },
    anchor: "center",
    background: {
      desktop: "stars",
      mobile: "disco",
    },
    innerBackground: {
      desktop: "disco",
      mobile: "disco",
    },
    mixBlendMode: "screen",
    overlayOpacity: 0,
    cells: [["play"]],
    animationStyle: "grow-c",
    soundtrack: "Gold Head Option 01",
    sfx: ["A1", "A2", "A3", "A4", "A5", "A6"],
    volume: 0.15,
  },

  {
    index: 4,
    name: "smiley",
    sticker: "smiley",
    button: "ok",
    scale: {
      desktop: 1.4,
      mobile: 1.2,
      button: 0.8,
    },
    anchor: "center",
    background: {
      desktop: "smiley",
      mobile: null,
    },
    innerBackground: {
      desktop: null,
      mobile: null,
    },
    overlay:
      "linear-gradient(180deg, rgba(38, 145, 0, 0.5) 0%, rgba(132, 238, 255, 0.5) 100%)",

    cells: [["flowers"]],
    animationStyle: "grow-d-rotate",
    soundtrack: "Spiral 05 Stereo",

    sfx: ["G1", "G2", "G3", "G4", "G5", "G6"],
    volume: 0.75,
  },
  {
    index: 5,
    name: "heart",
    sticker: "heart",
    button: "blob",
    scale: {
      desktop: 0.8,
      mobile: 0.8,
      button: 1,
    },
    anchor: "center",
    background: {
      desktop: "heart",
    },
    innerBackground: {
      desktop: null,
      mobile: null,
    },
    overlayOpacity: 0,

    cells: [["standing-man"]],
    animationStyle: "grow-c",
    soundtrack: "VHS Girl Heartbeat",
    sfx: ["F1", "F2", "F3", "F4", "F5", "F6"],
    volume: 0.35,
  },
];
