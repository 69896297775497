import stickersSrcWebP from "url:../assets/stickers/webp/*.webp";
import stickersSrcGif from "url:../assets/stickers/*.gif";
import buttonsSrcWebP from "url:../assets/buttons/webp/*.webp";
import buttonsSrcGif from "url:../assets/buttons/*.gif";
import backgroundsDesktop from "url:../assets/backgrounds/desktop/*.mp4";
import backgroundsMobile from "url:../assets/backgrounds/mobile/*.mp4";
import spritesSrc from "url:../assets/sprites/*.png";
import audioSrc from "url:../audio/*.mp3";
import { Howl, Howler } from "howler/src/howler.core";
Howler.autoSuspend = false;
export default class Loader {
  constructor() {
    this.progress = 0;
    this.currentProgress = 0;
    this.loaded = 0;
    this.total = 0;
    this.assets = {
      backgrounds: {
        desktop: {},
        mobile: {},
      },
      stickers: {},
      buttons: {},
      sprites: {},
      audio: {},
    };
    this.batch = [];
    this.loader = null;
    this.init();
  }

  async init() {
    this.loader = document.querySelector(".loader");
    if (this.supportsWebP()) {
      console.log("WebP format is supported");
      this.batch = [
        ...Object.values(stickersSrcWebP),
        ...Object.values(buttonsSrcWebP),
        ...Object.values(backgroundsDesktop),
        ...Object.values(backgroundsMobile),
        ...Object.values(spritesSrc),
        ...Object.values(audioSrc),
      ];
      // Assign sources to assets object
      this.assets.stickers = { ...stickersSrcWebP };
      this.assets.buttons = { ...buttonsSrcWebP };
    } else {
      console.warn("WebP format is not supported, loading GIFs.");
      this.batch = [
        ...Object.values(stickersSrcGif),
        ...Object.values(buttonsSrcGif),
        ...Object.values(backgroundsDesktop),
        ...Object.values(backgroundsMobile),
        ...Object.values(spritesSrc),
        ...Object.values(audioSrc),
      ];
      // Assign sources to assets object

      this.assets.stickers = { ...stickersSrcGif };
      this.assets.buttons = { ...buttonsSrcGif };
    }
    this.assets.backgrounds = {
      desktop: { ...backgroundsDesktop },
      mobile: { ...backgroundsMobile },
    };
    this.assets.sprites = {
      ...spritesSrc,
    };
    this.assets.audio = {
      ...audioSrc,
    };
  }
  async load() {
    if (this.progress != 1) {
      return new Promise((resolve, reject) => {
        this.total = this.batch.length;
        for (let i = 0; i < this.batch.length; i++) {
          this.loadEach(this.batch[i], resolve);
        }
      });
    }
  }
  async loadAudio() {
    const deviceDetect = require("device-detect")();

    const mobiles = [
      "iPhone",
      "iPad",
      "iPod",
      "Blackberry",
      "WindowsMobile",
      "Android",
    ];
    const audiosPairs = Object.entries(audioSrc);
    for (let i = 0; i < audiosPairs.length; i++) {
      this.assets.audio[audiosPairs[i][0]] = new Howl({
        src: audiosPairs[i][1],
        loop: false,
        volume: 0.15,
        // html5: mobiles.includes(deviceDetect.device),
      });
    }
  }
  loadEach(src, resolve) {
    const request = new XMLHttpRequest();
    request.open("GET", src);
    request.onloadend = (e) => {
      this.loaded++;
      this.progress = this.loaded / this.total;
      this.loader.style.setProperty("--progress", this.progress);
      if (this.progress === 1) {
        setTimeout(() => {
          resolve();
        }, 1500);
      }
    };
    request.send();
  }
  supportsWebP() {
    const canvas = document.createElement("canvas");
    if (!!(canvas.getContext && canvas.getContext("2d"))) {
      return canvas.toDataURL("image/webp").indexOf("data:image/webp") == 0;
    } else {
      return false;
    }
  }
}
