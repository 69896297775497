const _temp0 = require("url:./disco.webp");
const _temp1 = require("url:./heart.webp");
const _temp2 = require("url:./jack.webp");
const _temp3 = require("url:./juniper.webp");
const _temp4 = require("url:./selfie.webp");
const _temp5 = require("url:./smiley.webp");
module.exports = {
  "disco": _temp0,
  "heart": _temp1,
  "jack": _temp2,
  "juniper": _temp3,
  "selfie": _temp4,
  "smiley": _temp5
}