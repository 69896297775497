{
  "bubbles": {
    "frames": 15,
    "framerate": 24,
    "columns": 5,
    "rows": 3
  },

  "standing-man": {
    "frames": 14,
    "framerate": 24,
    "columns": 5,
    "rows": 3
  },

  "pink-letter-i": {
    "frames": 45,
    "framerate": 12,
    "columns": 5,
    "rows": 9
  },
  "pink-letter-n": {
    "frames": 45,
    "framerate": 12,
    "columns": 5,
    "rows": 9
  },
  "pink-letter-l": {
    "frames": 45,
    "framerate": 12,
    "columns": 5,
    "rows": 9
  },
  "pink-letter-o": {
    "frames": 45,
    "framerate": 12,
    "columns": 5,
    "rows": 9
  },
  "pink-letter-v": {
    "frames": 45,
    "framerate": 12,
    "columns": 5,
    "rows": 9
  },
  "pink-letter-e": {
    "frames": 45,
    "framerate": 12,
    "columns": 5,
    "rows": 9
  },

  "flowers": {
    "frames": 41,
    "framerate": 12,
    "columns": 5,
    "rows": 9
  },
  "smiley-clap": {
    "frames": 24,
    "framerate": 12,
    "columns": 5,
    "rows": 5
  },
  "smiley-heart": {
    "frames": 25,
    "framerate": 12,
    "columns": 5,
    "rows": 5
  },
  "smiley-vomit": {
    "frames": 48,
    "framerate": 12,
    "columns": 5,
    "rows": 10
  },
  "smiley-pray": {
    "frames": 48,
    "framerate": 12,
    "columns": 5,
    "rows": 10
  },
  "smiley-thumbs-up": {
    "frames": 48,
    "framerate": 12,
    "columns": 5,
    "rows": 10
  },
  "smiley-brain-boom": {
    "frames": 48,
    "framerate": 12,
    "columns": 5,
    "rows": 10
  },
  "smiley-joy": {
    "frames": 48,
    "framerate": 12,
    "columns": 5,
    "rows": 10
  },
  "play": {
    "frames": 8,
    "framerate": 12,
    "columns": 5,
    "rows": 2
  }
}
