const _temp0 = require("url:./blob.webp");
const _temp1 = require("url:./eclipse.webp");
const _temp2 = require("url:./noise.webp");
const _temp3 = require("url:./ok-3d.webp");
const _temp4 = require("url:./ok.webp");
const _temp5 = require("url:./space.webp");
module.exports = {
  "blob": _temp0,
  "eclipse": _temp1,
  "noise": _temp2,
  "ok-3d": _temp3,
  "ok": _temp4,
  "space": _temp5
}