const _temp0 = require("url:./disco.mp4");
const _temp1 = require("url:./heart.mp4");
const _temp2 = require("url:./jack.mp4");
const _temp3 = require("url:./juniper.mp4");
const _temp4 = require("url:./smiley.mp4");
const _temp5 = require("url:./stars.mp4");
module.exports = {
  "disco": _temp0,
  "heart": _temp1,
  "jack": _temp2,
  "juniper": _temp3,
  "smiley": _temp4,
  "stars": _temp5
}