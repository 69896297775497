import "./imigon-cell.js";
import "./sprite-player.js";
import spritesData from "./sprites.json";
import audioSrcs from "url:./audio/*.mp3";

import channelsData from "./channels";
import { Howler } from "howler/src/howler.core";
import Loader from "./utils/loader.js";

Howler.autoSuspend = false;
let isMuted = true;
Howler.mute(true);
const loader = new Loader();

let isSignupVisible = false;
let isPressingButton = false;

const channels = channelsData;

let currentChannel = 0;
let currentChannelData = channels[currentChannel];

let elementUnderPointer = null;
let cells = [];
let enterTimeouts = [];

const wrapper = document.querySelector(".wrapper");
const phoneOuter = document.querySelector(".phone__outer");
const footer = document.querySelector(".phone__footer");
const inner = document.querySelector(".phone__inner");
const phoneBg = document.querySelector(".phone__bg");
const stickerWrapper = document.querySelector(".sticker__wrapper");
const outerFillBg = document.querySelector(".outer-fill-bg"); // The fill background which shows on landscape devices (not shown on portrait).
const signupWrapper = document.querySelector(".signup");
const signupForm = document.querySelector(".signup form");
const signupEmailInput = document.querySelector(".signup__input");
const signupEmailValue = document.querySelector(".signup__value");
const signupSubmit = document.querySelector(".signup__submit");
const soundButton = document.querySelector(".sound");
const buttonsWrapper = document.querySelector(".buttons");
const switchButton = document.querySelector(".buttons__switch");
const signupToggles = [...document.querySelectorAll(".signup-toggle")];
const time = document.querySelector(".phone__time");
const loadingVideo = document.querySelector(".loading__logo.vid");
const loadingPoster = document.querySelector(".loading__logo.img");
const loading = document.querySelector(".loading");
const audioOnBtn = document.querySelector("#audio-on");
const audioOffBtn = document.querySelector("#audio-off");
let hasSubmitted = false;
let resetTimeout;
let phoneBounds;

const delay = (ms = 999) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const resetForm = () => {
  clearTimeout(resetTimeout);
  footer.classList.remove("phone__footer--signup");
  signupSubmit.style.opacity = 1;
  signupSubmit.style.pointerEvents = "";
  document.body.classList.remove("loading");
  signupWrapper.classList.remove("signup--success");
  signupWrapper.classList.remove("signup--error");
  signupEmailInput.value = "";
  signupEmailValue.innerHTML = "";
  signupSubmit.innerHTML = "JOIN";
  hasSubmitted = false;
  signupSubmit.disabled = false;
};
const pressButtonSound = () => {
  isPressingButton = true;
  loader.assets.audio["H-KD1"].play();
  setTimeout(() => {
    isPressingButton = false;
  }, 400);
};
const onSubmitSignup = async (e) => {
  e.preventDefault();
  pressButtonSound();
  if (hasSubmitted) {
    resetForm();
    return;
  }

  document.body.classList.add("loading");
  const formData = new FormData(e.target);
  const url = "/api/signup";
  const data = {};
  for (const [key, value] of formData.entries()) {
    data[key] = value;
  }
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    hasSubmitted = true;
    await fetch(url, options);
    signupSubmit.style.fontSize = "";
    signupEmailInput.style.fontSize = "";
    document.body.classList.remove("loading");
    signupWrapper.classList.add("signup--success");
    signupSubmit.style.opacity = 0;
    signupSubmit.style.pointerEvents = "none";
    resetTimeout = setTimeout(resetForm, 3333);
  } catch (error) {
    document.body.classList.remove("loading");
    signupSubmit.style.fontSize = "";
    signupEmailInput.style.fontSize = "";
    signupWrapper.classList.add("signup--error");
    signupSubmit.disabled = true;
    resetTimeout = setTimeout(resetForm, 3333);
    console.error(error);
  }
};
const inputTextSize = parseFloat(
  window
    .getComputedStyle(signupEmailInput, null)
    .getPropertyValue("font-size")
    .replace("px", "")
);
const onChangeInput = (e) => {
  signupEmailValue.innerHTML = e.target.value;
  const textWidth = signupEmailValue.clientWidth;
  const inputWidth = signupEmailInput.clientWidth;

  const ratio = Math.min(1, inputWidth / textWidth);

  if (inputTextSize * ratio >= 12) {
    signupSubmit.style.fontSize = `${inputTextSize * ratio}px`;
    signupEmailInput.style.fontSize = `${inputTextSize * ratio}px`;
  } else {
    signupSubmit.style.fontSize = `12px`;
    signupEmailInput.style.fontSize = `12px`;
  }
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value);
  if (isEmailValid) {
    signupSubmit.disabled = false;
  } else {
    signupSubmit.disabled = true;
  }
};

const onMouseMove = (e) => {
  if (
    e.target.classList.contains("buttons__button") ||
    e.target.classList.contains("signup") ||
    e.target.classList.contains("buttons__switch") ||
    e.target.classList.contains("signup__input-wrapper") ||
    e.target.classList.contains("signup__input") ||
    e.target.classList.contains("signup__submit")
  )
    return;
  const { clientX, clientY } = e.touches ? e.touches[0] : e;
  const elemsAtPoint = document.elementsFromPoint(clientX, clientY);
  const imigonCellElement = elemsAtPoint.find(
    (el) => el.tagName === "IMIGON-CELL"
  );
  if (!imigonCellElement) return;

  if (imigonCellElement !== elementUnderPointer) {
    elementUnderPointer = imigonCellElement;
    console.log(currentChannelData.volume);
    loader.assets.audio[elementUnderPointer.audio].volume(
      currentChannelData.volume
    );
    if (elementUnderPointer.trigger) {
      elementUnderPointer.trigger();
      if (!isMuted && !isPressingButton)
        loader.assets.audio[elementUnderPointer.audio].play();
    }
  }
  rotatePhone(e);
};
function rotatePhone(e) {
  const mouseX = e.clientX;
  const mouseY = e.clientY;
  const leftX = mouseX - phoneBounds.x;
  const topY = mouseY - phoneBounds.y;
  const center = {
    x: leftX - phoneBounds.width / 2,
    y: topY - phoneBounds.height / 2,
  };
  const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

  const isMobileLayout =
    window.innerHeight > window.innerWidth && window.innerWidth <= 810;
  if (isMobileLayout) {
    phoneOuter.style.transform = `
    rotate3d(0)
  `;
  } else {
    phoneOuter.style.transform = `
    scale(1.07)
    rotate3d(
      ${center.y / 100},
      ${-center.x / 100},
      0,
      ${Math.log(distance) * 1.4}deg
    )
  `;
  }
}
function rotatePhoneAccelerometer(e) {
  var accelerationX = e.accelerationIncludingGravity.x;
  var accelerationY = e.accelerationIncludingGravity.y;
  let xdeg = accelerationX * 5;
  let ydeg = accelerationY * 2;
  const isMobileLayout =
    window.innerHeight > window.innerWidth && window.innerWidth < 743;
  if (!isMobileLayout) {
    phoneOuter.style.transform = `
    scale(1.07)
    rotateX(
      ${-ydeg}deg
    )
    rotateY(
      ${-xdeg}deg
    )
    
  `;
  }
}

let cellVisibilityTimeout;
const updateChannel = async () => {
  wrapper.classList.add("cells-hidden");
  document.body.classList.remove("bg-loaded");
  document.body.classList.remove("inner-bg-loaded");

  outerFillBg.addEventListener("canplay", () => {
    document.body.classList.add("bg-loaded");
  });
  phoneBg.addEventListener("canplay", () => {
    document.body.classList.add("inner-bg-loaded");
  });

  document.querySelector(".phone__sticker.visible").classList.remove("visible");
  stickerWrapper.children[currentChannelData.index].classList.add("visible");
  document
    .querySelector(".buttons__switch-img.visible")
    .classList.remove("visible");
  switchButton.children[currentChannelData.index].classList.add("visible");

  const isMobileLayout = window.innerHeight > window.innerWidth;
  if (isMobileLayout && window.innerWidth < 743) {
    outerFillBg.src = loader.assets.backgrounds.mobile?.[
      currentChannelData.background.mobile
    ]
      ? loader.assets.backgrounds.mobile[currentChannelData.background.mobile]
      : loader.assets.backgrounds.desktop[
          currentChannelData.background.desktop
        ];
  } else {
    outerFillBg.src = currentChannelData.background.desktop
      ? loader.assets.backgrounds.desktop[currentChannelData.background.desktop]
      : null;
  }
  if (isMobileLayout && window.innerWidth < 743) {
    currentChannelData.innerBackground.mobile
      ? (phoneBg.src =
          loader.assets.backgrounds.mobile[
            currentChannelData.innerBackground.mobile
          ])
      : (phoneBg.src = null);
  } else {
    currentChannelData.innerBackground.desktop
      ? (phoneBg.src =
          loader.assets.backgrounds.desktop[
            currentChannelData.innerBackground.desktop
          ])
      : (phoneBg.src = null);
  }
  if (currentChannelData.overlay) {
    document.body.classList.add("custom-bg");
    footer.classList.add("custom-bg");
  } else {
    inner.style.background = "";
    document.body.classList.remove("custom-bg");
    footer.classList.remove("custom-bg");
  }
  document.body.classList.add("sticker-loaded");

  clearTimeout(cellVisibilityTimeout);
};
const updateCells = async () => {
  cells = [];

  enterTimeouts.forEach((timeout) => clearTimeout(timeout));
  enterTimeouts.splice(0, enterTimeouts.length);
  cells.splice(0, cells.length);
  while (
    wrapper.lastChild &&
    wrapper.lastChild?.tagName !== "IMG" &&
    wrapper.lastChild?.tagName !== "VIDEO"
  ) {
    wrapper.lastChild.remove();
  }
  const cellsPerRow = (() => {
    if (window.innerWidth <= 768) return 6;
    if (window.innerWidth <= 1024) return 12;
    if (window.innerWidth <= 1400) return 18;
    if (window.innerWidth <= 2000) return 24;
    return 30;
  })();
  const size = Math.ceil(window.innerWidth / cellsPerRow);
  const cols = Math.ceil(window.innerWidth / size);
  const rows = Math.ceil(window.innerHeight / size);
  const loadPromises = [];

  let count = 0;

  const sfx = currentChannelData.sfx;

  for (let y = 0; y < rows; y++) {
    const row = document.createElement("div");
    row.style.whiteSpace = "nowrap";
    wrapper.appendChild(row);

    for (let x = 0; x < cols; x++) {
      const audioKey = sfx[(x + y) % sfx.length];
      const cell = document.createElement("imigon-cell");
      cell.style.width = `${size}px`;
      cells.push(cell);
      loadPromises.push(
        new Promise((resolve, reject) => {
          cell.addEventListener("load", resolve);
        })
      );

      const index = y + x;
      const cellDataIndex = index % currentChannelData.cells.length;
      cell.mixBlendMode = currentChannelData.mixBlendMode || "normal";
      cell.animationStyle = currentChannelData.animationStyle;
      cell.sprites = currentChannelData.cells[cellDataIndex].map(
        (spriteKey) => {
          return {
            ...spritesData[spriteKey],
            src: loader.assets.sprites[spriteKey],
          };
        }
      );
      cell.audio = audioKey;
      cell.timeoffset = count * 3;
      row.appendChild(cell);
      count++;
    }
  }
  await Promise.all(loadPromises);

  for (let i = 0; i < cells.length; i++) {
    enterTimeouts.push(
      setTimeout(() => {
        cells[i].enter();
      }, i * 1)
    );
  }
  wrapper.classList.remove("cells-hidden");
};
let cTimeout;

const incrementChannel = async () => {
  pressButtonSound();
  currentChannel =
    currentChannel < channels.length - 1 ? currentChannel + 1 : 0;
  currentChannelData = channels[currentChannel];
  clearTimeout(cTimeout);
  cTimeout = setTimeout(async () => {
    await updateCells();
  }, 400);
  await Promise.all([updateChannel()]);
};

const toggleSignup = () => {
  isSignupVisible = !isSignupVisible;

  footer.classList.toggle("phone__footer--signup");

  if (isSignupVisible) {
    [...signupWrapper.querySelectorAll("input, button")].forEach(
      (el) => (el.tabIndex = "0")
    );
    [...buttonsWrapper.querySelectorAll("button")].forEach(
      (el) => (el.tabIndex = "-1")
    );
  } else {
    [...signupWrapper.querySelectorAll("input, button")].forEach(
      (el) => (el.tabIndex = "-1")
    );
    [...buttonsWrapper.querySelectorAll("button")].forEach(
      (el) => (el.tabIndex = "0")
    );
  }
};

const setTime = () => {
  const now = new Date();
  time.innerHTML = now
    .toLocaleTimeString("en-US", { hour12: false })
    .substring(0, 5);

  setTimeout(setTime, 2222);
};
const handleStickers = () => {
  for (let i = 0; i < channels.length; i++) {
    const img = document.createElement("img");
    img.src = loader.assets.stickers[channels[i].sticker];
    img.classList.add("phone__sticker");
    if (i === 0) img.classList.add("visible");
    img.style.setProperty("--scale", channels[i].scale.desktop);
    img.style.setProperty("--scale-mobile", channels[i].scale.mobile);
    img.classList.add(`anchor__${channels[i].anchor}`);
    stickerWrapper.append(img);
  }
};
const handleButtons = () => {
  for (let i = 0; i < channels.length; i++) {
    const img = document.createElement("img");
    img.src = loader.assets.buttons[channels[i].button];
    img.classList.add("buttons__switch-img");

    img.style.setProperty("--scale", channels[i].scale.button);

    if (i === 0) img.classList.add("visible");
    switchButton.append(img);
  }
};

const begin = async () => {
  console.log();

  loadingVideo.play();

  setTimeout(async () => {
    document.body.classList.add("loop-loaded");

    loadingVideo.removeEventListener("canplay", begin);

    let resizeTimeout;
    setTime();
    await loader.load();
    await loader.loadAudio();
    handleStickers();
    handleButtons();
    await updateCells();
    await Promise.all([updateChannel(), delay()]);
    let isMobileLayout =
      window.innerHeight > window.innerWidth && window.innerWidth < 810;

    wrapper.classList.remove("cells-hidden");

    document.body.classList.add("loaded-ready");
    function permission(e) {
      if (e.target.id === "audio-on") {
        handleSound(false);
      } else {
        handleSound(true);
      }
      if (
        typeof DeviceMotionEvent !== "undefined" &&
        typeof DeviceMotionEvent.requestPermission === "function" &&
        window.innerHeight > window.innerWidth &&
        window.innerWidth > 743 &&
        window.innerWidth <= 810
      ) {
        // (optional) Do something before API request prompt.
        DeviceMotionEvent.requestPermission()
          .then((response) => {
            // (optional) Do something after API prompt dismissed.
            if (response == "granted") {
              window.addEventListener("devicemotion", rotatePhoneAccelerometer);
            }
          })
          .catch(console.error)
          .finally(() => {
            document.body.classList.add("cta-confirmed");
            loading.style.opacity = 0;
            loading.style.pointerEvents = "none";
          });
      } else {
        document.body.classList.add("cta-confirmed");
        loading.style.opacity = 0;
        loading.style.pointerEvents = "none";
      }
      outerFillBg.play();

      switchButton.addEventListener("click", incrementChannel);
      window.addEventListener(
        "resize",
        () => {
          clearTimeout(resizeTimeout);
          resizeTimeout = setTimeout(() => {
            updateCells();
          }, 99);
          if (
            (window.innerHeight > window.innerWidth &&
              window.innerWidth < 810) !== isMobileLayout
          ) {
            isMobileLayout =
              window.innerHeight > window.innerWidth && window.innerWidth < 810;
            updateChannel();
          }
          phoneBounds = phoneOuter.getBoundingClientRect();
        },
        {
          passive: true,
        }
      );
      phoneBounds = phoneOuter.getBoundingClientRect();
      setTimeout(() => {
        window.addEventListener("touchstart", onMouseMove, { passive: true });
        window.addEventListener("mousemove", onMouseMove, { passive: true });
        window.addEventListener("touchmove", onMouseMove, { passive: true });
        window.addEventListener("touchmove", (e) => e.preventDefault());
      }, 500);
      soundButton.addEventListener("click", () => {
        isMuted = !isMuted;
        soundButton.classList.toggle("mute");
        Howler.stop();
        Howler.mute(isMuted);
      });

      signupForm.addEventListener("submit", onSubmitSignup);
      signupEmailInput.addEventListener("keyup", onChangeInput);

      signupToggles.forEach((button) => {
        button.addEventListener("click", toggleSignup);
      });
    }
    audioOnBtn.addEventListener("click", permission);
    audioOffBtn.addEventListener("click", permission);
  }, 500);
};
const handleSound = (muted) => {
  if (muted) {
    isMuted = true;
    soundButton.classList.add("mute");
    Howler.stop();
    Howler.mute(true);
  } else {
    isMuted = false;
    soundButton.classList.remove("mute");
    Howler.stop();
    Howler.mute(false);
  }
};
if (
  navigator.userAgent.includes("Firefox") &&
  navigator.userAgent.includes("Android")
) {
  loadingVideo.classList.toggle("visible");
  loadingPoster.classList.toggle("visible");
}
begin();
