import Stats from "stats.js";

class Ticker {
  time = 0;
  delta = 0;
  correction = 1;
  then = null;
  now = null;
  callbacks = [];

  constructor() {
    this.animate = this.animate.bind(this);

    if (
      typeof window !== undefined &&
      ["fps"].find((value) => window?.location.search.indexOf(value) > -1)
    ) {
      this.stats = new Stats();
      this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
      document.body.appendChild(this.stats.dom);
    }
  }

  calculateDelta() {
    this.then = this.now ? this.now : null;
    this.now = performance ? performance.now() : Date.now();
    this.delta = this.then ? this.now - this.then : 0;
    this.time += this.delta;
    this.correction = this.delta ? this.delta / 16.666 : 1;
    this.correction = Math.min(this.correction, 4);
  }

  animate() {
    if (this.stats) this.stats.begin();
    this.calculateDelta();
    this.callbacks.forEach((callback) =>
      callback({
        delta: this.delta,
        correction: this.correction,
        time: this.time,
      })
    );
    this.raf = requestAnimationFrame(this.animate);
    if (this.stats) this.stats.end();
  }

  addCallback(callback) {
    if (this.hasCallback(callback)) return;
    this.callbacks.push(callback);

    cancelAnimationFrame(this.raf);
    this.then = Date.now();
    this.raf = requestAnimationFrame(this.animate);
  }

  removeCallback(callback) {
    if (!callback) return;
    const index = this.callbacks.indexOf(callback);
    if (index === -1) return;
    this.callbacks.splice(index, 1);
    if (this.callbacks.length === 0) {
      cancelAnimationFrame(this.raf);
    }
  }

  hasCallback(callback) {
    return this.callbacks.indexOf(callback) > -1;
  }
}

export default new Ticker();
