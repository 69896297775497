const _temp0 = require("url:./bubbles.png");
const _temp1 = require("url:./flowers.png");
const _temp2 = require("url:./pink-letter-e.png");
const _temp3 = require("url:./pink-letter-i.png");
const _temp4 = require("url:./pink-letter-l.png");
const _temp5 = require("url:./pink-letter-n.png");
const _temp6 = require("url:./pink-letter-o.png");
const _temp7 = require("url:./pink-letter-v.png");
const _temp8 = require("url:./play.png");
const _temp9 = require("url:./smiley-brain-boom.png");
const _temp10 = require("url:./smiley-clap.png");
const _temp11 = require("url:./smiley-heart.png");
const _temp12 = require("url:./smiley-pray.png");
const _temp13 = require("url:./smiley-thumbs-up.png");
const _temp14 = require("url:./smiley-vomit.png");
const _temp15 = require("url:./standing-man.png");
module.exports = {
  "bubbles": _temp0,
  "flowers": _temp1,
  "pink-letter-e": _temp2,
  "pink-letter-i": _temp3,
  "pink-letter-l": _temp4,
  "pink-letter-n": _temp5,
  "pink-letter-o": _temp6,
  "pink-letter-v": _temp7,
  "play": _temp8,
  "smiley-brain-boom": _temp9,
  "smiley-clap": _temp10,
  "smiley-heart": _temp11,
  "smiley-pray": _temp12,
  "smiley-thumbs-up": _temp13,
  "smiley-vomit": _temp14,
  "standing-man": _temp15
}