import gsap from "gsap/all";
import { Howl, Howler } from "howler/src/howler.core";

Howler.autoSuspend = false;

class ImigonCell extends HTMLElement {
  isAnimating = false;
  isEntered = false;
  spritePlayers = [];

  constructor() {
    super();
    this.trigger = this.trigger.bind(this);
  }

  static get validAnimationStyles() {
    return ["grow-c", "grow-d", "quick-swap", "grow-d-rotate"];
  }

  static get observedAttributes() {
    return ["audio", "sprites", "timeoffset", "animation-style"];
  }

  async connectedCallback() {
    this.attachShadow({ mode: "open" }).innerHTML = `
      <style>
        :host {
          width: 111px;
          aspect-ratio: 1/1;
          display: inline-block;
          position: relative;
          mix-blend-mode: ${this.mixBlendMode};
        }
        
        sprite-player {
          width: 100%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          will-change: transform;
          pointer-events: none;
          visibility: hidden;
          mix-blend-mode: ${this.mixBlendMode};
        }
      </style>
    `;

    const loadPromises = [];
    if (!this.getAttribute("timeoffset")) {
      this.timeoffset = 0;
    }

    this.sprites.forEach(({ src, frames, framerate, columns, rows }) => {
      const player = document.createElement("sprite-player");
      this.spritePlayers.push(player);
      loadPromises.push(
        new Promise((resolve) => {
          player.addEventListener("load", resolve);
        })
      );

      player.frames = frames;
      player.columns = columns;
      player.rows = rows;
      player.framerate = framerate;
      player.src = src;
      player.playstate = "paused";
      player.timeoffset = this.timeoffset;

      this.shadowRoot.appendChild(player);
    });
    this._audio = this.audio;
    this.dispatchEvent(new Event("load"));
  }

  disconnectedCallback() {}

  async attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) {
      return;
    }

    switch (name) {
      case "mix-blend-mode":
        this.mixBlendMode = "normal";
        break;
      case "sprites":
        this.sprites = JSON.parse(newValue);
        break;
      case "audio":
        this.audio = newValue;
        break;
      case "timeoffset":
        this.timeoffset = parseInt(newValue);
        break;
      case "animation-style":
        this.animationStyle = newValue;
        break;
    }
  }

  enter() {
    switch (this.animationStyle) {
      case "grow-c":
      case "grow-d":
        this.isEntered = true;
        break;
      case "grow-d-rotate":
        this.isEntered = true;
        break;
      case "quick-swap":
        this.isAnimating = true;
        const tl = gsap.timeline({
          onComplete: () => {
            this.isAnimating = false;
            this.isEntered = true;
          },
        });
        tl.fromTo(
          this.spritePlayers[0],
          { scale: 0 },
          {
            scale: 1,
            duration: 0.5,
            onStart: () => {
              this.spritePlayers[0].playstate = "running";
              this.spritePlayers[0].style.visibility = "visible";
            },
          }
        );
        break;
    }
  }

  trigger() {
    if (!this.isEntered) return;
    if (this.isAnimating) return;
    // this._audio.play();
    this.isAnimating = true;
    let tl;

    switch (this.animationStyle) {
      case "grow-c":
      case "grow-d":
        tl = gsap.timeline({
          onComplete: () => (this.isAnimating = false),
        });
        tl.fromTo(
          this.spritePlayers[0],
          { scale: 0 },
          {
            scale: 1,
            duration: 0.8,
            ease: "power1.inOut",
            onStart: () => {
              this.spritePlayers[0].playstate = "running";
              this.spritePlayers[0].style.visibility = "visible";
            },
          }
        );
        tl.to(this.spritePlayers[0], {
          scale: this.animationStyle === "grow-c" ? 1.5 : 2,
          duration: this.animationStyle === "grow-c" ? 0.8 : 0.6,
          ease:
            this.animationStyle === "grow-c" ? "bounce.out" : "power2.inOut",
        });
        tl.to(this.spritePlayers[0], {
          scale: 0,
          duration: 0.8,
          ease: "power2.out",
          delay: 0.2,
          onComplete: () => {
            this.spritePlayers[0].playstate = "paused";
            this.spritePlayers[0].style.visibility = "hidden";
          },
        });
        break;
      case "grow-d-rotate":
        tl = gsap.timeline({
          onComplete: () => (this.isAnimating = false),
        });
        tl.fromTo(
          this.spritePlayers[0],
          { scale: 0, rotate: 0 },
          {
            scale: 1,
            rotate: 360,
            duration: 0.8,
            ease: "power1.inOut",
            onStart: () => {
              this.spritePlayers[0].playstate = "running";
              this.spritePlayers[0].style.visibility = "visible";
            },
          }
        );
        tl.to(this.spritePlayers[0], {
          scale: this.animationStyle === "grow-c" ? 1.5 : 2,
          rotate: 360,
          duration: this.animationStyle === "grow-c" ? 0.8 : 0.6,
          ease:
            this.animationStyle === "grow-c" ? "bounce.out" : "power2.inOut",
        });
        tl.to(this.spritePlayers[0], {
          scale: 0,
          duration: 0.8,
          ease: "power2.out",
          delay: 0.2,
          onComplete: () => {
            this.spritePlayers[0].playstate = "paused";
            this.spritePlayers[0].style.visibility = "hidden";
          },
        });
        break;
      case "quick-swap":
        tl = gsap.timeline({
          onComplete: () => (this.isAnimating = false),
          onStart: () => {
            this.spritePlayers[1].playstate = "paused";
            this.spritePlayers[1].style.visibility = "hidden";
          },
        });
        tl.fromTo(
          this.spritePlayers[0],
          { scale: 1 },
          {
            scale: 2,
            duration: 0.3,
            ease: "power1.out",
          }
        );
        tl.to(this.spritePlayers[0], {
          scale: 0,
          duration: 0.8,
          ease: "power1.out",
          onComplete: () => {
            this.spritePlayers[0].playstate = "paused";
            this.spritePlayers[0].style.visibility = "hidden";
          },
        });
        tl.fromTo(
          this.spritePlayers[1],
          { scale: 0 },
          {
            scale: 2,
            duration: 0.8,
            ease: "power1.out",
            onStart: () => {
              this.spritePlayers[1].playstate = "playing";
              this.spritePlayers[1].style.visibility = "visible";
            },
          }
        );
        tl.to(this.spritePlayers[1], {
          scale: 0,
          duration: 0.8,
          ease: "power1.out",
          onComplete: () => {
            this.spritePlayers[1].playstate = "paused";
            this.spritePlayers[1].style.visibility = "hidden";
          },
        });
        tl.to(this.spritePlayers[0], {
          scale: 1,
          duration: 0.8,
          ease: "power1.out",
          onStart: () => {
            this.spritePlayers[0].playstate = "running";
            this.spritePlayers[0].style.visibility = "visible";
          },
        });
        break;
    }
  }

  validateAnimationStyle() {
    if (!this.animationStyle || !this.sprites) return;

    if (!ImigonCell.validAnimationStyles.includes(this.animationStyle)) {
      return console.warn(`Invalid animation style: ${this.animationStyle}`);
    }

    switch (this.animationStyle) {
      case "grow-c":
        if (this.sprites.length !== 1) {
          console.warn(
            `Animation style ${this.animationStyle} requires 1 sprite. (Currently ${this.sprites.length} sprites)`
          );
        }
        break;
      case "quick-swap":
        if (this.sprites.length !== 2) {
          console.warn(
            `Animation style ${this.animationStyle} requires 2 sprites. (Currently ${this.sprites.length} sprites)`
          );
        }
        break;
      default:
    }
  }

  get animationStyle() {
    return this.getAttribute("animation-style");
  }

  set animationStyle(value) {
    this.setAttribute("animation-style", value);
    this.validateAnimationStyle();
  }

  get sprites() {
    return JSON.parse(this.getAttribute("sprites"));
  }

  set sprites(value) {
    value.forEach(({ src, frames, framerate }, index) => {
      if (this.spritePlayers[index]) {
        this.spritePlayers[index].src = src;
        this.spritePlayers[index].frames = frames;
        this.spritePlayers[index].framerate = framerate;
      }
    });

    this.setAttribute("sprites", JSON.stringify(value));
    this.validateAnimationStyle();
  }

  get audio() {
    return this.getAttribute("audio");
  }

  set audio(value) {
    this.setAttribute("audio", value);
  }

  get timeoffset() {
    return parseInt(this.getAttribute("timeoffset"));
  }

  set timeoffset(value) {
    this.setAttribute("timeoffset", value);

    this.spritePlayers.forEach((player) => (player.timeoffset = value));
  }
}

customElements.define("imigon-cell", ImigonCell);
