const _temp0 = require("url:./A1.mp3");
const _temp1 = require("url:./A2.mp3");
const _temp2 = require("url:./A3.mp3");
const _temp3 = require("url:./A4.mp3");
const _temp4 = require("url:./A5.mp3");
const _temp5 = require("url:./A6.mp3");
const _temp6 = require("url:./B1.mp3");
const _temp7 = require("url:./B2.mp3");
const _temp8 = require("url:./B3.mp3");
const _temp9 = require("url:./B4.mp3");
const _temp10 = require("url:./B5.mp3");
const _temp11 = require("url:./B6.mp3");
const _temp12 = require("url:./C1.mp3");
const _temp13 = require("url:./C2.mp3");
const _temp14 = require("url:./C3.mp3");
const _temp15 = require("url:./C4.mp3");
const _temp16 = require("url:./C5.mp3");
const _temp17 = require("url:./C6.mp3");
const _temp18 = require("url:./D1.mp3");
const _temp19 = require("url:./D2.mp3");
const _temp20 = require("url:./D3.mp3");
const _temp21 = require("url:./D4.mp3");
const _temp22 = require("url:./D5.mp3");
const _temp23 = require("url:./D6.mp3");
const _temp24 = require("url:./F1.mp3");
const _temp25 = require("url:./F2.mp3");
const _temp26 = require("url:./F3.mp3");
const _temp27 = require("url:./F4.mp3");
const _temp28 = require("url:./F5.mp3");
const _temp29 = require("url:./F6.mp3");
const _temp30 = require("url:./G1.mp3");
const _temp31 = require("url:./G2.mp3");
const _temp32 = require("url:./G3.mp3");
const _temp33 = require("url:./G4.mp3");
const _temp34 = require("url:./G5.mp3");
const _temp35 = require("url:./G6.mp3");
const _temp36 = require("url:./H-HH.mp3");
const _temp37 = require("url:./H-KD1.mp3");
const _temp38 = require("url:./H-KD2.mp3");
const _temp39 = require("url:./H-KD3.mp3");
const _temp40 = require("url:./H-POP.mp3");
const _temp41 = require("url:./H-RIM.mp3");
module.exports = {
  "A1": _temp0,
  "A2": _temp1,
  "A3": _temp2,
  "A4": _temp3,
  "A5": _temp4,
  "A6": _temp5,
  "B1": _temp6,
  "B2": _temp7,
  "B3": _temp8,
  "B4": _temp9,
  "B5": _temp10,
  "B6": _temp11,
  "C1": _temp12,
  "C2": _temp13,
  "C3": _temp14,
  "C4": _temp15,
  "C5": _temp16,
  "C6": _temp17,
  "D1": _temp18,
  "D2": _temp19,
  "D3": _temp20,
  "D4": _temp21,
  "D5": _temp22,
  "D6": _temp23,
  "F1": _temp24,
  "F2": _temp25,
  "F3": _temp26,
  "F4": _temp27,
  "F5": _temp28,
  "F6": _temp29,
  "G1": _temp30,
  "G2": _temp31,
  "G3": _temp32,
  "G4": _temp33,
  "G5": _temp34,
  "G6": _temp35,
  "H-HH": _temp36,
  "H-KD1": _temp37,
  "H-KD2": _temp38,
  "H-KD3": _temp39,
  "H-POP": _temp40,
  "H-RIM": _temp41
}