import Ticker from "./Ticker";

const LOAD_PROMISES = new Map();

const load = async (src) => {
  const loadPromise = LOAD_PROMISES.get(src);
  if (loadPromise) return loadPromise;
  const promise = new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = () => reject("Could not load image");
    image.src = src;
  });

  LOAD_PROMISES.set(src, promise);
  return promise;
};

class SpritePlayer extends HTMLElement {
  width = 1;
  height = 1;

  constructor() {
    super();
    this.update = this.update.bind(this);
  }

  static get observedAttributes() {
    return [
      "src",
      "framerate",
      "frames",
      "frame",
      "playstate",
      "timeoffset",
      "columns",
    ];
  }

  connectedCallback() {
    this.attachShadow({ mode: "open" }).innerHTML = `
      <style>
        :host {
          display: inline-block;
          position: relative;
          aspect-ratio: 1/1;
        }
        #sprite {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: 100px 100px; 
          background-position: 0px 0%;
          background-repeat: no-repeat;
          backface-visibility: hidden;
          will-change: background-position;
        }
      </style>
      <div id="sprite"></div>
    `;

    if (!this.getAttribute("timeoffset")) {
      this.timeoffset = 0;
    }
    this._sprite = this.shadowRoot.querySelector("#sprite");
    this._sprite.style.animationPlayState = this.playstate;
    this.resizeObserver = new ResizeObserver((entries) => {
      this.width = entries[0].contentRect.width;
      this.height = entries[0].contentRect.height;
      this._sprite.style.backgroundSize = `${this.width * this.columns}px ${
        this.height * this.rows
      }px`;
    });
    this.resizeObserver.observe(this);
  }

  disconnectedCallback() {
    this.resizeObserver.unobserve(this);
    Ticker.removeCallback(this.update);
  }

  async attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) {
      return;
    }

    switch (name) {
      case "src":
        if (newValue) {
          const image = await load(newValue);
          this.style.aspectRatio = `${image.width / this.columns}/${
            image.height / this.rows
          }`;
          if (this._sprite) {
            this._sprite.style.backgroundImage = `url(${this.src})`;
          }
          this.dispatchEvent(new Event("load"));
        }
        break;
      case "frames":
        this.frames = newValue;
        break;
      case "framerate":
        this.framerate = newValue;
      case "playstate":
        this.playstate = newValue === "paused" ? "paused" : "running";
        break;
      case "frame":
        this.frame = parseInt(newValue);
        break;
      case "timeoffset":
        this.timeoffset = parseInt(newValue);
        break;
      case "columns":
        this.columns = parseInt(newValue);
        break;
      case "rows":
        this.rows = parseInt(newValue);
        break;
    }
  }

  get frames() {
    return this.getAttribute("frames");
  }

  set frames(value) {
    this.setAttribute("frames", value);
  }

  get framerate() {
    return this.getAttribute("framerate");
  }

  set framerate(value) {
    this.setAttribute("framerate", value);
  }

  get playstate() {
    return this.getAttribute("playstate");
  }

  set playstate(value) {
    if (this.playstate === "running") {
      Ticker.addCallback(this.update);
    } else {
      Ticker.removeCallback(this.update);
    }
    this.setAttribute("playstate", value);
  }

  get src() {
    return this.getAttribute("src");
  }

  set src(value) {
    this.setAttribute("src", value);
  }

  get columns() {
    return parseInt(this.getAttribute("columns"));
  }

  set columns(value) {
    this.setAttribute("columns", value);
  }

  get rows() {
    return parseInt(this.getAttribute("rows"));
  }

  set rows(value) {
    this.setAttribute("rows", value);
  }

  get frame() {
    return parseInt(this.getAttribute("src"));
  }

  set frame(value) {
    if (value === this.frame) return;
    const currentRow = Math.floor(value / this.columns);
    const currentColumn = value % this.columns;

    const backgroundPositionX =
      -this.width * this.columns * (currentColumn / this.columns);
    const backgroundPositionY =
      -this.height * this.rows * (currentRow / this.rows);

    this._sprite.style.backgroundPosition = `${backgroundPositionX}px ${backgroundPositionY}px`;
    this.setAttribute("frame", value);
  }

  get timeoffset() {
    return parseInt(this.getAttribute("timeoffset"));
  }

  set timeoffset(value) {
    this.setAttribute("timeoffset", value);
  }

  update({ time }) {
    const secs = (time + this.timeoffset) / 1000;
    const frame = Math.floor((secs * this.framerate) % this.frames);
    this.frame = frame;
  }
}

customElements.define("sprite-player", SpritePlayer);
